<template>
  <div class="kt-portlet" :id="sticky" :style="{zIndex: stickyZIndex}" :data-margin-top="stickyMarginTop" v-bind:class="portletClass">
    <div
      class="kt-portlet__head"
      v-bind:class="headComputeClass"
      v-if="hasTitleSlot || title"
    >
      <div class="kt-portlet__head-label">
        <slot name="title" v-if="hasTitleSlot"></slot>
        <h3 class="kt-portlet__head-title" v-if="!hasTitleSlot">
          {{ title }}
        </h3>
      </div>
      <div class="kt-portlet__head-toolbar">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <div v-if="hasCleanBodySlot" class="kt-portlet__body" v-bind:class="cleanBodyClassCompute">
      <slot name="cleanBody"></slot>
    </div>
    <div
        v-if="hasBodySlot"
        class="kt-portlet__body"
        v-bind:class="bodyClassCompute"
    >
      <slot name="body"></slot>
    </div>
    <div class="kt-portlet__foot kt-portlet__body--fit" :class="footComputeClass" v-if="hasFootSlot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "KTPortlet",
    props: {
      /**
       * String title
       */
      title: String,
      /**
       * Set portlet head size. Eg. md, lg, sm, etc.
       */
      headSize: String,
      /**
       * Set portlet to fluid
       */
      fluidHeight: Boolean,
      /**
       * Set portlet to fluid in half
       */
      fluidHalfHeight: Boolean,
      /**
       * Set overlay head
       */
      headOverlay: Boolean,
      /**
       * Set extra class for portlet head
       */
      headClass: String,
      /**
       * Set bo-border class for portlet head
       */
      headNoBorder: Boolean,
      /**
       * Set extra class for portlet body
       */
      bodyClass: String,
      /**
       * Set portlet body to fit
       */
      bodyFit: Boolean,
      /**
       * Set portlet body to fluid
       */
      bodyFluid: Boolean,
      /**
       * Set extra class for portlet clean body
       */
      cleanBodyClass: String,

      footClass: String,
      /**
       * Set portlet body to fit
       */
      cleanBodyFit: Boolean,
      /**
       * Set portlet body to fluid
       */
      cleanBodyFluid: Boolean,

      sticky: String,
      stickyZIndex: String,
      stickyMarginTop: [String, Number]
    },
    directives: {  },
    components: {},
    mounted() {
      if ( this.sticky ){
        let Sticky = require('sticky-js');
        new Sticky(`#${this.sticky}`);
      }
    },
    methods: {},
    computed: {
      portletClass() {
        const cls = {
          "kt-portlet--height-fluid": this.fluidHeight,
          "kt-portlet--height-fluid-half": this.fluidHalfHeight,
          "kt-portlet--head-overlay": this.headOverlay,
          "kt-portlet--head-no-border": this.headNoBorder,
        };
        cls[this.headSizeClass] = this.headSizeClass;
        return cls;
      },
      headComputeClass() {
        const cls = {
          "border-0": this.headNoBorder,
        };
        let headClasses = this.headClass && this.headClass.split(' ') || [];
        headClasses.forEach((item) => {
          cls[item] = item
        });
        return cls;
      },
      footComputeClass() {
        const cls = {};
        let footClasses = this.footClass && this.footClass.split(' ') || [];
        footClasses.forEach((item) => {
          cls[item] = item
        });
        return cls;
      },
      cleanBodyClassCompute() {
        const cls = {
          "kt-portlet__body--fit": this.cleanBodyFit,
          "kt-portlet__body--fluid": this.cleanBodyFluid,
        };
        let cleanBodyClasses = this.cleanBodyClass && this.cleanBodyClass.split(' ') || [];
        cleanBodyClasses.forEach((item) => {
          cls[item] = item
        });
        return cls;
      },
      bodyClassCompute() {
        const cls = {
          "kt-portlet__body--fit": this.bodyFit,
          "kt-portlet__body--fluid": this.bodyFluid,
        };
        let bodyClasses = this.bodyClass && this.bodyClass.split(' ') || [];
        bodyClasses.forEach((item) => {
          cls[item] = item
        });
        return cls;
      },
      hasCleanBodySlot() {
        return !!this.$slots["cleanBody"];
      },
      hasBodySlot() {
        return !!this.$slots["body"];
      },
      hasTitleSlot() {
        return !!this.$slots["title"];
      },
      hasFootSlot() {
        return !!this.$slots["foot"];
      },
      headSizeClass() {
        if (this.headSize) {
          return `kt-portlet--head-${this.headSize}`;
        }
        return false;
      },
    }
  };
</script>
